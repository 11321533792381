import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";

import _map from "lodash/map";
import _findIndex from "lodash/findIndex";

import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

import { Accordion } from "../Accordion";
import { Select } from "../Select";
// import ColorPicker from "../ColorPicker";
import { Slider } from "../Slider";
import { MultiForm } from "../MultiForm";
import { InputText } from "../InputText";
import { Switch } from "../Switch";
import { LabelInfo } from "../LabelInfo";
import { Checkbox } from "../Checkbox";
import { Icon } from "../Icon";
import { PartialDate } from "../PartialDate";
import { QuillRTE } from "../QuillRTE";
import { AssetListEditor } from "../AssetListEditor";
import { Chooser } from "../Chooser";
import { ThumbSelect } from "../ThumbSelect";
import "./List.scss";

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data ? props.data : "" };
    if (document) {
      this.quill = require("react-quill");
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  generateLabel(labelText, infoText, isRequired) {
    const addRequired = typeof isRequired !== "undefined" ? isRequired : false;
    return (
      <div className="label-container">
        <span>
          {labelText}{" "}
          {addRequired ? <span style={{ color: "red" }}>*</span> : null}
        </span>
        {infoText ? <LabelInfo info={infoText} /> : null}
      </div>
    );
  }

  generateTwoColumns(Component, props, label) {
    return (
      <div className="col-sm-12 cms-list-item">
        <div className="col-sm-6 left-item">
          {props.icon ? (
            <div
              className="icon-container"
              style={
                this.props.styleName === "boxed"
                  ? { background: "#31BCFF" } // this.props.muiTheme.palette.primary1Color
                  : null
              }
            >
              <Icon
                icon={props.icon}
                width={20}
                style={{ float: "left", marginRight: 10 }}
              />
            </div>
          ) : null}
          <span className="list-item-text">{label}</span>
        </div>
        <div className="col-sm-6 right-item">
          <Component {...props} />
        </div>
      </div>
    );
  }

  render() {
    const {
      className,
      icon,
      item,
      slugBase,
      handleInputChange,
      needRefresh,
      styleName
    } = this.props;
    const data = this.state.data;
    if (!item) return <div />;
    const { param } = item; // This does not seem to work, param == undefinded
    switch (item.type) {
      case "section":
      case "link":
        item.slug =
          item.slug.search(slugBase) === -1
            ? slugBase + "/" + item.slug
            : item.slug;
        //const linkClick = ()=>{navigateTo(item.slug)}
        return (
          <div className={`cms-list-item link ${styleName}`}>
            <NavLink
              to={item.slug}
              className="cms-list-item-container"
              activeClassName="active"
            >
              <div className="col-sm-12 cms-list-item link">
                <div className="col-sm-7 left-item">
                  {item.icon ? (
                    <div
                      className="icon-container"
                      style={
                        styleName === "boxed"
                          ? { background: "#31BCFF" } // muiTheme.palette.primary1Color
                          : null
                      }
                    >
                      <Icon
                        icon={item.icon}
                        width={20}
                        style={{ float: "left", marginRight: 10 }}
                      />
                    </div>
                  ) : null}
                  <div className="list-item-text">{item.label} </div>
                  {item.sublabel ? (
                    <div className="list-item-sublabel">{item.sublabel} </div>
                  ) : null}
                </div>
                <div className="col-sm-5 right-item">
                  <ChevronRight />
                </div>
              </div>
            </NavLink>
          </div>
        );
      case "accordion":
        return <Accordion {...item} />;

      case "navigate":
        const navClick = () => {
          this.props.history.push(item.to || item.slug);
        };
        const leftIcon = icon ? (
          <Icon
            icon={icon}
            width={20}
            style={{ float: "left", marginRight: 10 }}
          />
        ) : (
          {}
        );
        return (
          <NavLink
            to={item.to || item.slug}
            className={`cms-list-item navigate-link ${styleName}`}
          >
            <MenuItem
              className="navigate-item"
              onClick={navClick}
              leftIcon={leftIcon}
              primaryText={item.label}
              rightIcon={<ActionInfo />}
            />
          </NavLink>
        );
      case "button":
        const buttonClick = item.onClick
          ? item.onClick
          : () => {
              this.props.history.push(item.to);
            };
        return <Button {...item} onClick={buttonClick} />;
      case "divider":
        //return <ItemDivider {...item} />;
        return (
          <div className={`list-group-header ${className}`}>
            {item.showLine ? <hr className="cms-list-border"></hr> : null}
            {item.label ? <h2>{item.label}</h2> : null}
          </div>
        );
      default:
        const value = data !== undefined ? data : item.value ? item.value : "";
        const label = this.generateLabel(
          item.label,
          item.infoText,
          item.isRequired
        );
        const { options, inlineLabel } = item;

        let myProps = {};
        switch (item.inputType || item.type) {
          /**
           * @NOTE: ColorPicker is originating problems - disabled until we can look into it
           */
          // case 'color':
          //   myProps = {
          //     colorValue: value,
          //     onChange: handleInputChange,
          //     dataProperty: param,
          //     needRefresh: needRefresh
          //   };
          //   if (inlineLabel)
          //     return ( <ColorPicker {...myProps} label={label}/>);
          //
          //   return generateTwoColumns(ColorPicker, myProps, label);
          case "select":
          case "select-multiple":
            const generateOption = (labelPrefix, valuePrefix) => i => ({
              label: labelPrefix ? `${labelPrefix}${i}` : `${i}`,
              value: valuePrefix ? `${valuePrefix}${i}` : i
            });
            const selectOptions = item.optionsRange
              ? item.optionsRangeZeroBased
                ? [...Array(item.optionsRange + 1).keys()].map(
                    generateOption(
                      item.optionsRangeLabelPrefix,
                      item.optionsRangeValuePrefix
                    )
                  )
                : Array.from(
                    { length: item.optionsRange },
                    (_, i) => i + 1
                  ).map(
                    generateOption(
                      item.optionsRangeLabelPrefix,
                      item.optionsRangeValuePrefix
                    )
                  )
              : options;

            // the value will be the INDEX of the options that the value from db matches
            const selectedIndex = _findIndex(selectOptions, { value: value });

            myProps = {
              options: selectOptions,
              styleName,
              selected: selectedIndex,
              value,
              hasLabel: !!item.label,
              onChange: handleInputChange,
              dataProperty: param,
              needRefresh,
              multiple: item.type === "select-multiple" ? true : false
            };
            if (inlineLabel)
              return <Select {...myProps} floatingLabel={label} />;

            return this.generateTwoColumns(Select, myProps, label);

          case "thumbSelect":
            const generateOption2 = valuePrefix => i => ({
              value: valuePrefix ? `${valuePrefix}${i}` : i,
              _id: i
            });
            const thumbSelectOptions = item.optionsRange
              ? item.optionsRangeZeroBased
                ? [...Array(item.optionsRange + 1).keys()].map(
                    generateOption2(item.optionsRangeValuePrefix)
                  )
                : Array.from(
                    { length: item.optionsRange },
                    (_, i) => i + 1
                  ).map(generateOption2(item.optionsRangeValuePrefix))
              : item.options;

            myProps = {
              id: param,
              name: param,
              className: className || param,
              options: thumbSelectOptions,
              selected: value,
              onChange: handleInputChange,
              component: item.component
            };

            if (inlineLabel) return <ThumbSelect {...myProps} label={label} />;

            return this.generateTwoColumns(ThumbSelect, myProps, label);

          case "slider":
            myProps = {
              styleName: styleName,
              hasLabel: !!item.label,
              min: item.min,
              max: item.max,
              value: value,
              onChange: handleInputChange,
              dataProperty: param,
              needRefresh: needRefresh
            };
            if (inlineLabel) return <Slider {...myProps} label={label} />;

            return this.generateTwoColumns(Slider, myProps, label);
          case "switch":
          case "toggle":
            myProps = {
              toggled: value,
              name: item.param,
              styleName: styleName,
              hasLabel: !!item.label,
              onChange: handleInputChange,
              dataProperty: param,
              needRefresh: needRefresh
            };
            if (inlineLabel) return <Switch {...myProps} label={label} />;

            return this.generateTwoColumns(Switch, myProps, label);
          case "radio":
            myProps = {};
            return (
              <div className="cms-input-text radio">
                {label ? label : null}
                <RadioGroup
                  name={item.param}
                  value={value}
                  onChange={handleInputChange}
                >
                  {_map(options, subitem => {
                    return (
                      // <RadioButton
                      //   value={subitem.value}
                      //   key={subitem.value}
                      //   label={subitem.label}
                      //   style={subitem.style}
                      // />
                      // Do we need to style the radio button?
                      <FormControlLabel
                        key={subitem.value}
                        value={subitem.value}
                        control={<Radio />}
                        label={subitem.label}
                        labelPlacement="start"
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            );
          case "checkbox":
            myProps = {
              valueFromInput: value,
              key: item.param,
              styleName: styleName,
              hasLabel: !!item.label,
              label: label,
              onChange: handleInputChange,
              dataProperty: param,
              needRefresh: needRefresh,
              labelPosition: inlineLabel ? "left" : "right"
            };
            return <Checkbox {...myProps} />;

          // 6/1/2024 - No evidence that this form element is used.
          // case "popupList":
          //   //const popListIndex = _findIndex(options, {'value': value});selected:popListIndex,
          //   myProps = {
          //     options: options,
          //     onChange: handleInputChange,
          //     value: value,
          //     classId: item.classId,
          //     dataProperty: param
          //   };
          //   if (inlineLabel) return <PopupList {...myProps} label={label} />;

          //   return this.generateTwoColumns(PopupList, myProps, label);

          case "multiForm":
            myProps = {
              options: options,
              styleName: styleName,
              injectLanguage: this.props.injectLanguage,
              language: this.props.language,
              handleEditClick: item.handleEditClick
                ? item.handleEditClick
                : this.props.handleEditClick,
              handleAddClick: item.handleAddClick
                ? item.handleAddClick
                : this.props.handleAddClick,
              handleDeleteClick: item.handleDeleteClick
                ? item.handleDeleteClick
                : this.props.handleDeleteClick,
              handleInputChange: handleInputChange,
              list: value ? (value.list ? value.list : value) : null,
              param,
              classId: item.classId,
              dataProperty: param
            };
            return <MultiForm {...myProps} label={label} />;

          // 6/1/2024 - No evidence that this form element is used.
          // case "popupListWithFilter":
          //   const popListFilterIndex = _findIndex(options, { value: value });
          //   myProps = {
          //     options: options,
          //     onChange: handleInputChange,
          //     selectedIndex: popListFilterIndex,
          //     selectedValue: value,
          //     classId: item.classId,
          //     dataProperty: param,
          //     needRefresh: needRefresh
          //   };
          //   if (inlineLabel)
          //     return <PopupListWithFilter {...myProps} label={label} />;

          //   return this.generateTwoColumns(PopupListWithFilter, myProps, label);

          case "rte":
            myProps = {
              id: item.label,
              name: param,
              theme: "bubble",
              styleName: styleName,
              hasLabel: !!item.label,
              defaultValue: value,
              onChange: handleInputChange,
              key: param || item.label,
              toolbarType: item.inputType || item.type
            };
            return <QuillRTE {...myProps} label={label} />;

          case "rte-ext":
          case "rte-ext-class":
            myProps = {
              id: item.label,
              name: param,
              theme: "snow",
              styleName: styleName,
              hasLabel: !!item.label,
              defaultValue: value,
              onChange: handleInputChange,
              key: param || item.label,
              toolbarType: item.inputType || item.type
            };
            return <QuillRTE {...myProps} label={label} />;

          case "partialDate":
            myProps = {
              id: item.label,
              name: param,
              styleName: styleName,
              hasLabel: !!item.label,
              value: value,
              handleInputChange: handleInputChange,
              key: param || item.label
            };
            return <PartialDate {...myProps} label={label} />;

          case "assetEditor":
            myProps = {
              styleName: styleName,
              hasLabel: !!item.label,
              label,
              handleEditClick: item.handleEditClick
                ? item.handleEditClick
                : this.props.handleEditClick,
              handleAddClick: item.handleAddClick
                ? item.handleAddClick
                : this.props.handleAddClick,
              handleDeleteClick: item.handleDeleteClick
                ? item.handleDeleteClick
                : this.props.handleDeleteClick,
              handleFrameSetterFunc: item.handleFrameSetterFunc
                ? item.handleFrameSetterFunc
                : this.props.handleFrameSetterFunc,
              param,
              ...value
            };
            if (typeof this.props.row !== "undefined") {
              myProps.row = this.props.row;
            }
            return <AssetListEditor {...myProps} />;

          case "readonly":
            myProps = {
              id: item.label,
              name: param,
              hasLabel: !!item.label,
              dataProperty: param,
              defaultValue: value,
              onChange: handleInputChange,
              styleName: styleName,
              multiLine: item.type === "textarea",
              key: param || item.label,
              needRefresh: needRefresh,
              disabled: true
            };
            if (inlineLabel) return <InputText {...myProps} label={label} />;

            return this.generateTwoColumns(InputText, myProps, label);

          case "password":
            myProps = {
              id: item.label,
              name: param,
              hasLabel: !!item.label,
              dataProperty: param,
              defaultValue: value,
              onChange: handleInputChange,
              styleName: styleName,
              multiLine: item.type === "textarea",
              key: param || item.label,
              needRefresh: needRefresh,
              type: "password",
              autoComplete: "off"
            };
            if (inlineLabel) return <InputText {...myProps} label={label} />;

            return this.generateTwoColumns(InputText, myProps, label);

          case "chooser":
            return (
              <Chooser
                thumbCloudPublicId={value.thumbCloudPublicId}
                cloudName={value.cloudName}
                title={value.title}
                actionLabel={item.actionLabel}
                actionOnClick={item.handleActionClick}
              />
            );

          default:
            const multiLine = item.type === "textarea";
            myProps = {
              id: item.label,
              hasLabel: !!item.label,
              name: param,
              styleName: styleName,
              disabled:
                typeof item.disabled === "undefined"
                  ? undefined
                  : item.disabled,
              dataProperty: param,
              defaultValue: value,
              onChange: handleInputChange,
              multiLine: multiLine,
              key: param || item.label,
              needRefresh: needRefresh,
              autoComplete: item.autoComplete
            };
            if (inlineLabel) return <InputText {...myProps} label={label} />;

            return this.generateTwoColumns(InputText, myProps, label);
        }
    }
  }
}
ListItem.defaultProps = {
  needRefresh: false,
  styleName: "standard"
};

ListItem.propTypes = {
  handleInputChange: PropTypes.func,
  icon: PropTypes.string,
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  needRefresh: PropTypes.bool,
  toggleNeedRefresh: PropTypes.func,
  styleName: PropTypes.string,
  slugBase: PropTypes.string,
  injectLanguage: PropTypes.bool,
  language: PropTypes.string,
  handleEditClick: PropTypes.func,
  handleAddClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleFrameSetterFunc: PropTypes.func,
  row: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // For multiForm we need to pass the row number for the action handlers
  data: PropTypes.any
};

export default withRouter(ListItem);
