import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import _map from "lodash/map";
import _startCase from "lodash/startCase";

import { Button } from "@material-ui/core";

import { Dialog, TableGrid } from "components";
import { sceneListBrowserMapStateToProps } from "mapToProps/sceneEditor";
import ContentContainer from "modules/ContentContainer";
import ActionFormModal from "../../ActionFormModal";
import * as storyActions from "actions/storyEditor";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss"; // need to leverage the same selector actions from story

/**
 * SceneBrowser
 * card browser for scenes
 */
class SceneListBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true,
      addModalOpen: false,
      deleteModalOpen: false,
      selected: []
    };

    // NOTE: the bindings allow for use of this reference
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.proceedToAdd = this.proceedToAdd.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false });
    const selected = [];
    _map(this.state.selected, item => {
      selected.push(this.props.scene.subScenes[item]);
    });
    this.props.deleteSubScene(selected);
  }

  render() {
    const {
      pageTitle,
      isLoading,
      sidebarMode,
      addFormElements,
      sceneType,
      tableGridItems,
      header
    } = this.props;

    // Dispatch Methods
    const { createScene } = this.props;

    if (isLoading) {
      return <div>{`Loading ${sceneType}s to browse.`}</div>;
    }

    const topBar = (
      <div className="filters-bar-container">
        {this.state.selected.length > 0 ? (
          <Button
            variant="contained"
            id="delete-button"
            color="primary"
            className="new-asset-button-v2"
            style={{ textTransform: "none" }}
            onTouchTap={e => {
              e.stopPropagation();
              e.preventDefault();
              this.openDeleteModal();
            }}
          >
            DELETE
          </Button>
        ) : (
          <Button
            variant="contained"
            id="new-button"
            color="primary"
            className="new-asset-button-v2"
            style={{ textTransform: "none" }}
            onTouchTap={e => {
              e.stopPropagation();
              e.preventDefault();
              this.openAddModal();
            }}
          >
            NEW
          </Button>
        )}
      </div>
    );

    const headerStyle = {
      backgroundColor: "#f2f2f2"
    };

    addFormElements.title.label = _startCase(sceneType);
    addFormElements.title.param = sceneType;

    return (
      <div className="scene-list-browser">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <ContentContainer
          backgroundColor="white"
          isLoading={isLoading}
          className="content-container"
          sidebarMode={sidebarMode}
          topBar={topBar}
        >
          {tableGridItems ? (
            <TableGrid
              items={tableGridItems}
              header={header}
              handleRowSelectionFunc={this.handleRowSelection}
              idNode="id"
              headerStyle={headerStyle}
              multiSelectable
              sortable
              editAction={this.props.editQuestion}
            />
          ) : null}
        </ContentContainer>
        <ActionFormModal
          open={this.state.addModalOpen}
          closeAction={this.closeAddModal}
          handleSave={this.proceedToAdd}
          title={`Add New ${_startCase(sceneType)}`}
          form={addFormElements}
        />
        <Dialog
          content={`Once deleted, these ${sceneType}s cannot be restored`}
          handleConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          handleCancel={this.closeDeleteModal}
          bodyStyle={{ paddingTop: 20 }}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.deleteModalOpen}
          title={`Delete ${_startCase(sceneType)}?`}
        />
      </div>
    );
  }

  handleRowSelection(rows) {
    this.setState({ selected: rows });
  }

  openAddModal() {
    this.setState({ addModalOpen: true });
  }

  closeAddModal() {
    this.setState({ addModalOpen: false });
  }

  // Called when a non-collection item scene is added.
  proceedToAdd(data) {
    this.setState({ addModalOpen: false });
    let title;
    switch (this.props.storyType) {
      case "timeline":
        title = data.event;
        break;
      case "map":
        title = data.place;
        break;
      case "quiz-graded":
        title = data.question;
        break;
    }
    this.props.addSubScene(title);
  }

  componentDidUpdate() {
    if (this.props.navigateToNew) {
      this.props.goToScene(this.props.navigateToNew);
    }
  }
}

SceneListBrowser.defaultProps = {
  isLoading: false,
  storyBrowserUIState: {}
};

SceneListBrowser.propTypes = {
  isLoading: PropTypes.bool,
  tableGridItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  storyID: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  return sceneListBrowserMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    slugBase,
    storyID,
    sceneID,
    sceneTypeParam,
    subSceneTypeParam,
    titleIsLocalized
  } = stateProps;
  return Object.assign({}, stateProps, {
    editQuestion: questionID => {
      const url = `${slugBase}/${questionID}`;
      ownProps.history.push(url);
    },
    addSubScene: title => {
      dispatchProps.createSubScene(
        storyID,
        sceneID,
        { title },
        sceneTypeParam,
        subSceneTypeParam
      );
    },
    createScene: (storyID, sceneData) => {
      dispatchProps.createScene(storyID, sceneData, null, titleIsLocalized);
    },
    deleteSubScene: selected => {
      dispatchProps.deleteSubScene(sceneID, selected);
    },
    goToScene: sceneID => {
      ownProps.history.push(`${slugBase}/${sceneID}`);
    },
    deleteScene: (storyID, selected) => {
      dispatchProps.deleteScene(storyID, selected);
    }
  });
}
// storyActions,
export default connect(
  mapStateToProps,
  { ...storyActions, ...sceneActions },
  mergeProps
)(SceneListBrowser);
