import React, { Component } from "react";
import PropTypes from "prop-types";

import { Button, Dialog as MUIDialog } from "@material-ui/core";

/**
 * @description a  dialog that can be leveraged in variaous parts of CMS
 * could be a simple confirmation OR contain a form
 * @props handleConfirm
 * @props handleCancel
 * @props title
 * @props content
 */

export class Dialog extends Component {
  // allows for us to pass the form contents if it is a form...
  // store form values in the state
  //
  render() {
    const titleNode = (
      <div className="row style-header-container">
        <span className="style-header-row">{this.props.title || ""}</span>
      </div>
    );

    let confirmButtonProps = this.props.buttonStyle
      ? {
          ...this.props.buttonStyle.confirmButtonStyle,
          label: this.props.isProcessing
            ? this.props.processingLabel
            : this.props.confirmLabel,
          onTouchTap: this.props.handleConfirm,
          disabled:
            this.props.isProcessing || this.props.disableConfirmButton
              ? true
              : false,
          className: "confirm-button"
        }
      : {
          label: this.props.isProcessing
            ? this.props.processingLabel
            : this.props.confirmLabel,
          onTouchTap: this.props.handleConfirm,
          disabled:
            this.props.isProcessing || this.props.disableConfirmButton
              ? true
              : false,
          className: "confirm-button"
        };

    const cancelButtonProps = this.props.buttonStyle
      ? {
          ...this.props.buttonStyle.cancelButtonStyle,
          onTouchTap: this.props.handleCancel,
          label: this.props.cancelLabel,
          disabled: this.props.disableCancelButton,
          className: "cancel-button"
        }
      : {
          onTouchTap: this.props.handleCancel,
          label: this.props.cancelLabel,
          disabled: this.props.disableCancelButton,
          className: "cancel-button"
        };

    const ButtonConfirmComponent = () => {
      return this.props.buttonStyle ? (
        this.props.buttonStyle.type === "flat" ? (
          <Button {...confirmButtonProps} />
        ) : (
          <Button variant="contained" {...confirmButtonProps} />
        )
      ) : (
        <Button {...confirmButtonProps} />
      );
    };

    const ButtonCancelComponent = () => {
      return this.props.buttonStyle ? (
        this.props.buttonStyle.type === "flat" ? (
          <Button {...cancelButtonProps} />
        ) : (
          <Button variant="contained" {...cancelButtonProps} />
        )
      ) : (
        <Button {...cancelButtonProps} />
      );
    };

    let actions = [<ButtonCancelComponent />, <ButtonConfirmComponent />];

    if (this.props.processComplete) {
      actions = [
        <ButtonComponent
          {...this.props.buttonStyle.cancelButtonStyle}
          label={this.props.completeLabel}
          onTouchTap={this.props.handleCancel}
        />
      ];
    }

    return (
      <MUIDialog
        className={this.props.className || "app-dialog"}
        title={titleNode}
        modal={this.props.modal}
        actions={this.props.actions || actions}
        //actionsContainerClassName="style-actions-row"
        open={this.props.open}
        onRequestClose={this.props.handleCancel}
        style={this.props.style}
        titleStyle={this.props.titleStyle}
        bodyStyle={this.props.bodyStyle}
        contentStyle={this.props.contentStyle}
        actionsContainerStyle={this.props.actionsContainerStyle}
      >
        {this.props.content || "" || this.props.children}
      </MUIDialog>
    );
  }
}

Dialog.defaultProps = {
  confirmLabel: "Continue",
  cancelLabel: "Cancel",
  processingLabel: "Saving...",
  completeLabel: "OK",
  open: false,
  disableCancelButton: false,
  disableConfirmButton: false
};

Dialog.propTypes = {
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  processingLabel: PropTypes.string,
  completeLabel: PropTypes.string,
  open: PropTypes.bool,

  isProcessing: PropTypes.bool,
  processComplete: PropTypes.bool,

  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
  disableConfirmButton: PropTypes.bool,
  disableCancelButton: PropTypes.bool,

  // Styles
  style: PropTypes.object,
  bodyStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  buttonStyle: PropTypes.object, // Set of styles to define the action buttons
  actionsContainerStyle: PropTypes.object
};
