import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";
import _map from "lodash/map";
import _cloneDeep from "lodash/cloneDeep";

import { Button, MenuItem, Select } from "@material-ui/core";

import Logger from "utils/logger";
import { Dialog, List } from "components";
import ContentContainer from "modules/ContentContainer";
import { gradedResultsEditorMapStateToProps } from "mapToProps/sceneEditor";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";
import styles from "./styles.scss";

export class GradedResultEditor extends React.Component {
  constructor(props) {
    super(props);
    let data = {};
    if (this.props.activeEditingScene.elements) {
      data = _cloneDeep(this.props.activeEditingScene.elements);
    }
    this.state = {
      isDirty: false,
      data,
      activeLanguage: this.props.langID
    };

    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.debounceInputChange = _debounce(function (baseParam, param, value) {
      this.handleInputChanges.apply(this, [baseParam, param, value]);
    }, 500);
    this.handleAddAsset = this.handleAddAsset.bind(this);
    this.handleDeleteAsset = this.handleDeleteAsset.bind(this);
    this.proceedToLink = this.proceedToLink.bind(this);
    this.openDisplayRevertCancel = this.openDisplayRevertCancel.bind(this);
    this.closeDisplayRevertCancel = this.closeDisplayRevertCancel.bind(this);
    this.handleRevertActionFromModal =
      this.handleRevertActionFromModal.bind(this);
    this.handleAddAsset = this.handleAddAsset.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleCloseClicked = this.handleCloseClicked.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    /*
    We have to update the data and isDirty state props in case an
    asset has been added or removed from a feedback option. When an
    asset is added to a feedback option, the asset details are 
    acquired in a saga (because the saga can use a selector to get
    the asset details from the store), and then the reducer adds the
    asset details to the scene in the store. When an asset is removed,
    the asset is updated in the store.
    */

    if (typeof nextProps.activeEditingScene.isSaved !== "undefined") {
      this.setState({ isDirty: !nextProps.activeEditingScene.isSaved });
      //this.props.storySetIsSaved(nextProps.activeEditingScene.isSaved);
    }

    /*
    If the elements in the store don't match the elements in
    local state, update local state to match the store.
    */
    if (!_isEqual(nextProps.activeEditingScene.elements, this.state.data)) {
      this.setState({
        data: _cloneDeep(nextProps.activeEditingScene.elements)
      });
    }
  }

  render() {
    const { activeEditingScene, activeSlideAssetCloudId } = this.props;
    Logger.debug({ props: this.props }, "[GradedResultsEditor] - render");
    const topBar = (
      <div className="graded-quiz-editor-topbar">
        <Button
          variant="contained"
          color="primary"
          disabled={!this.state.isDirty}
          className="new-asset-button-v2"
          onClick={e => {
            e.stopPropagation();
            this.handleSave();
          }}
          style={{
            textTransform: "none",
            float: "right",
            marginRight: 50,
            marginTop: 15
          }}
        >
          SAVE
        </Button>

        <Select
          className="language-select"
          value={this.state.activeLanguage}
          onChange={this.handleLanguageChange}
        >
          {this.props.languages &&
            _map(this.props.languages, item => (
              <MenuItem
                key={item.id}
                value={item.id}
                primaryText={item.label}
              />
            ))}
        </Select>
      </div>
    );

    return (
      <div className="graded-quiz-result-editor">
        <Helmet>
          <title>Graded Quiz Results Editor</title>
        </Helmet>
        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          isLoading={this.props.isLoading}
          className="quiz-editor-content-container"
          sidebarMode={this.props.sidebarMode}
          closeAction={this.props.slugBase}
          handleCloseClicked={this.handleCloseClicked}
          confirmNavigation={this.state.isDirty}
          headerTheme="light"
          title="Results"
          headerMode="extended-extra"
          topBar={topBar}
        >
          <div className="graded-result-editor-container">
            <div className="result-form-container">
              {this.props.forms ? (
                <div className="tab-column column-general column-right-border">
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.results.grade}
                    handleInputChange={(param, value) =>
                      this.debounceInputChange("grade", param, value)
                    }
                  />
                  <div style={{ clear: "both" }} />
                  <List
                    styleName="plain"
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.results.retakeQuizLink}
                    handleInputChange={(param, value) =>
                      this.debounceInputChange("retakeQuizLink", param, value)
                    }
                  />
                </div>
              ) : null}
              <div className="tab-column column-feedback">
                {this.props.forms ? (
                  <List
                    styleName="plain"
                    handleAddClick={this.handleAddAsset}
                    handleDeleteClick={this.handleDeleteAsset}
                    data={this.state.data ? this.state.data : null}
                    items={this.props.forms.results.feedbackOptions}
                    handleInputChange={(param, value) => {
                      Logger.debug(
                        { param, value },
                        " ==> handleInputChange Bounced"
                      );
                      this.debounceInputChange("feedbackOptions", param, value);
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </ContentContainer>
        <Dialog
          content={
            'If you exit without saving, your changes will be lost! Press "Confirm" to discard your changes or "Cancel" to go back and save your changes.'
          }
          handleConfirm={this.handleRevertActionFromModal}
          confirmLabel="Confirm"
          bodyStyle={{ paddingTop: 20 }}
          handleCancel={this.closeDisplayRevertCancel}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.confirmModal}
          title="Warning: You Have Unsaved Changes"
        />
      </div>
    );
  }

  handleAddAsset(data) {
    this.props.openAssetSelectorFunc(
      { row: data.row, param: "feedback" },
      false
    );
  }

  handleAssetSelected(assetID, elementID) {
    this.props.sceneSetAssetElement(elementID, assetID);
  }

  // User is deleting a feedback option asset.
  handleDeleteAsset(assetData) {
    Logger.debug({ assetData }, "GradedResultEditor: handleDeleteAsset");
    this.props.sceneSetAssetElement(
      { param: "feedback", row: assetData.row },
      null
    );
  }

  handleSave() {
    this.setState({ isDirty: false });
    this.props.storySetIsSaved(true);
    this.props.updateScene(this.state.data);
  }

  handleCloseClicked() {
    this.handleSceneExit(this.props.slugBase);
  }

  handleSceneExit(targetLink) {
    Logger.debug({ targetLink }, "[GradedResultEditor] handleSceneExit");
    if (this.state.isDirty) {
      this.setState({ tempLink: targetLink }, () =>
        this.openDisplayRevertCancel()
      );
    } else {
      this.proceedToLink(targetLink);
    }
  }

  handleRevertActionFromModal() {
    this.props.storySetIsSaved(true);
    // this.props.sceneSetIsSaved(true);
    this.props.revertScene();
    this.closeDisplayRevertCancel();
    const link = this.state.tempLink;
    Logger.debug(
      { link },
      "[GradedResultEditor] - handleRevertActionFromModal"
    );
    this.setState({ tempLink: null, isDirty: false }, () =>
      this.proceedToLink(link)
    );
  }

  proceedToLink(link) {
    this.props.history.push(link);
  }

  handleInputChanges(baseParam, param, value) {
    Logger.debug(
      { baseParam, param, value },
      "[GradedResultEditor] - handleInputChanges"
    );
    /*
    There are forms that have asset editors and text input fields.
    We had to define a handleInputChanges handler for those forms
    to handle text changes. However, changes in the asset editor
    are handled elsewhere. If this is an asset editor, exit.
    */
    const isAsset = param.indexOf("Asset") > -1;
    if (isAsset) {
      return;
    }

    /*
    The forms are bound to state.data. Therefore, we have to keep
    state.data up to date with changes made by the user in the forms.
    Otherwise, the user's changes will be overwritten.
    */
    const data = _cloneDeep(this.state.data);

    /*
    The Quill RTE sometimes converts the empty string to the following 
    HTML characters: <p><br></p>. If that's the input change being 
    processed, ignore it. We do not, however, want to ignore a change 
    from a non-empty string to the Quill empty string (<p><br></p>).
    but we do want to convert the Quill empty string to an actual empty 
    string.
    */
    if (value === "<p><br></p>") {
      if (data[param] === "") {
        return;
      }
      value = "";
    }

    /*
    If the value of the altered form input doesn't equal the previous
    form input value, update state.data and trigger an update to the 
    store. Note that select list values are of type 'object'
    */
    if (typeof value === "object") {
      data[param] = { ...data[param], ...value };
      this.setState({ data });
      this.setState({ isDirty: true });
      this.props.handleInputChange(param, value, baseParam);
      this.props.storySetIsSaved(false);
    } else if (data[param] !== value) {
      data[param] = value;
      this.setState({ data });
      this.setState({ isDirty: true });
      this.props.handleInputChange(param, value, baseParam);
      this.props.storySetIsSaved(false);
    }
  }

  openDisplayRevertCancel() {
    this.setState({ confirmModal: true });
  }

  closeDisplayRevertCancel() {
    this.setState({ confirmModal: false });
  }

  handleLanguageChange(event, index, value) {
    this.setState({ activeLanguage: value });
    this.props.changeLanguage(value);
  }
}

GradedResultEditor.contextTypes = {
  router: PropTypes.object
};

GradedResultEditor.defaultProps = {};

GradedResultEditor.propTypes = {
  activeEditingScene: PropTypes.object,
  activeEditingSubScene: PropTypes.object,
  storyID: PropTypes.string,
  sceneID: PropTypes.string,
  elementID: PropTypes.string,
  scenes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  slugBase: PropTypes.string,
  type: PropTypes.string,
  openLayoutSelectorFunc: PropTypes.func,
  openAssetSelectorFunc: PropTypes.func,
  openSceneEditorModalFunc: PropTypes.func
};

function mapStateToProps(state, ownProps) {
  return gradedResultsEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    storyID,
    sceneID,
    slugBase,
    activeEditingScene,
    forms,
    langID,
    localizedElements,
    dataReadyForSave
  } = stateProps;
  return Object.assign({}, stateProps, {
    handleInputChange: (property, value, baseParam) => {
      /*
      This is a hack. The editor shouldn't have a hard-coded
      list of root-level properties. This should be externalized.
      Leaving it in place even though the isActive property can't
      be managed in the CMS right now. We'll eventually need some
      way of setting root-level scene properties.
      */
      const isRoot = property === "isActive";
      dispatchProps.setElementInput(
        property,
        value,
        langID,
        activeEditingScene._id,
        isRoot,
        false,
        localizedElements.includes(property),
        forms.results[baseParam]
      );
    },
    openAssetSelectorFunc: (param, multiple) => {
      ownProps.openAssetSelectorFunc(param, multiple);
    },
    sceneSetAssetElement: (elementID, assetID) => {
      Logger.debug(
        { elementID, assetID },
        "GradedResultEditor: sceneSetAssetElement"
      );
      dispatchProps.sceneSetAssetElement(
        activeEditingScene._id,
        elementID,
        assetID
      );
    },
    storySetIsSaved: isSaved => {
      dispatchProps.setStoryBrowserIsSaved(isSaved);
    },
    changeLanguage: langID => {
      dispatchProps.changeLanguage(langID);
    },
    updateScene: () => {
      const sceneType = "quiz-graded-results";
      dispatchProps.updateScene(
        activeEditingScene._id,
        dataReadyForSave,
        forms,
        sceneType
      );
    },
    revertScene: () => {
      dispatchProps.revertScene();
    }
  });
}

export default withRouter(
  connect(mapStateToProps, sceneActions, mergeProps, { withRef: true })(
    GradedResultEditor
  )
);
