import React from "react";
import PropTypes from "prop-types";

import { Checkbox, MenuItem, Select, TextField } from "@material-ui/core";

import "./InputText.scss";

export class PartialDate extends React.Component {
  constructor(props) {
    super(props);
    // console.log('Setting def: ', props.valueFromInput || props.defaultValue || '');
    this.state = {
      value: props.value || props.defaultValue || ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(param, value) {
    if (this.state[param] !== value) {
      // const newValue =
      //   param === "month" || param === "day" ? (val ? val + 1 : val) : val;
      this.setState({ value: { ...this.state.value, [param]: value } });
      if (typeof this.props.handleInputChange !== "undefined") {
        this.props.handleInputChange(this.props.name, { [param]: value });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps - state',this.props.name, this.props.defaultValue, ' - nextProps', nextProps.defaultValue);
    if (this.props.value !== nextProps.value) {
      // console.log('change happening');
      this.setState({
        value: nextProps.value
      });
    }
  }

  render() {
    const months = [
      {
        value: 1,
        label: "Jan"
      },
      {
        value: 2,
        label: "Feb"
      },
      {
        value: 3,
        label: "Mar"
      },
      {
        value: 4,
        label: "Apr"
      },
      {
        value: 5,
        label: "May"
      },
      {
        value: 6,
        label: "Jun"
      },
      {
        value: 7,
        label: "Jul"
      },
      {
        value: 8,
        label: "Aug"
      },
      {
        value: 9,
        label: "Sep"
      },
      {
        value: 10,
        label: "Oct"
      },
      {
        value: 11,
        label: "Nov"
      },
      {
        value: 12,
        label: "Dec"
      }
    ];

    const hasLabel = this.props.hasLabel;
    const isTextarea = !!this.props.multiLine;

    const floatingLabelStyle =
      this.props.styleName === "plain"
        ? {
            padding: "4px 9px",
            color: "rgba(0, 0, 0, 0.4)",
            top: 10,
            ...this.props.labelStyle
          }
        : this.props.labelStyle;

    const floatingLabelFocusStyle =
      this.props.styleName === "plain"
        ? {
            top: 21
          }
        : undefined;

    const style =
      this.props.styleName === "plain"
        ? {
            height: 50,
            ...this.props.inputStyle
          }
        : this.props.inputStyle;

    // console.log('textareaStyle', this.props.name, hasLabel, this.props.label);

    const inputStyle =
      this.props.styleName === "plain"
        ? isTextarea
          ? {
              height: 96
            }
          : {
              backgroundColor: "#f6f6f6",
              fontSize: 13,
              marginTop: 0,
              borderRadius: 4,
              padding: hasLabel ? "16px 8px 0px 8px" : "0 9px"
            }
        : null;

    const textareaInputStyle =
      this.props.styleName === "plain"
        ? {
            backgroundColor: "#f6f6f6",
            borderRadius: 4,
            // height: 50,
            fontSize: 13,
            padding: hasLabel ? "20px 7px 6px 7px" : "13px 9px",
            marginTop: 0,
            marginBottom: 0
          }
        : null;

    const inputStyleSelect =
      this.props.styleName === "plain"
        ? {
            backgroundColor: "#f6f6f6",
            fontSize: 13,
            marginTop: 0,
            borderRadius: 4,
            width: "100%",
            height: 50,
            boxSizing: "border-box",
            padding: "0 0 0 11px"
          }
        : this.props.style;

    const underlineStyle =
      this.props.styleName === "plain"
        ? {
            display: "none"
          }
        : this.props.underlineStyle;

    const floatingLabelStyleSelect =
      this.props.styleName === "plain"
        ? {
            top: 27,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.4)"
          }
        : undefined;

    const menuStyle =
      this.props.styleName === "plain"
        ? {
            marginTop: 5
          }
        : this.props.menuStyle;

    const days = Array(31).fill();

    return (
      <div
        className="cms-input-text partial-date-container"
        key={this.props.id}
      >
        {hasLabel ? (
          <div className="label-container">{this.props.label}</div>
        ) : null}
        <div className="inputs-container">
          <TextField
            type="text"
            className={`cms-input-text ${
              this.props.required &&
              (this.state.value === null ||
                this.state.value === undefined ||
                this.state.value === "")
                ? "invalid-input"
                : ""
            }`}
            id={this.props.id}
            underlineShow={this.props.styleName !== "plain"}
            disabled={this.props.disabled}
            name={this.props.name + "Year"}
            value={this.state.value ? this.state.value.year : ""}
            floatingLabelText="Year"
            floatingLabelFixed={true}
            floatingLabelStyle={floatingLabelStyle}
            floatingLabelFocusStyle={floatingLabelFocusStyle}
            floatingLabelShrinkStyle={floatingLabelFocusStyle}
            fullWidth={true}
            onChange={(e, value) => this.handleChange("year", value)}
            style={style}
            inputStyle={inputStyle}
            hintText={this.props.placeholder}
          />

          <Select
            value={this.state.value ? this.state.value.month : undefined}
            onChange={(e, value) => this.handleChange("month", value)}
            className="select-input"
            style={inputStyleSelect}
            autoWidth={true}
            underlineStyle={underlineStyle}
            floatingLabelText="Month"
            floatingLabelStyle={floatingLabelStyleSelect}
            menuStyle={menuStyle}
            listStyle={this.props.listStyle}
            selectedMenuItemStyle={this.props.selectedMenuItemStyle}
            menuItemStyle={this.props.menuItemStyle}
            floatingLabelFixed={true}
          >
            <MenuItem
              key="null-value"
              value={null}
              primaryText=""
              innerDivStyle={this.props.selectedMenuItemStyle}
            />
            {months.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  value={option.value}
                  primaryText={option.label}
                  innerDivStyle={this.props.selectedMenuItemStyle}
                />
              );
            })}
          </Select>

          <Select
            value={this.state.value ? this.state.value.day : undefined}
            onChange={(e, value) => this.handleChange("day", value)}
            className="select-input"
            autoWidth={true}
            style={inputStyleSelect}
            underlineStyle={underlineStyle}
            floatingLabelText="Day"
            floatingLabelStyle={floatingLabelStyleSelect}
            menuStyle={menuStyle}
            listStyle={this.props.listStyle}
            selectedMenuItemStyle={this.props.selectedMenuItemStyle}
            menuItemStyle={this.props.menuItemStyle}
            floatingLabelFixed={true}
          >
            <MenuItem
              key="null-value"
              value={null}
              primaryText=""
              innerDivStyle={this.props.selectedMenuItemStyle}
            />
            {Array(31)
              .fill()
              .map((_, i) => {
                const day = i + 1;
                return (
                  <MenuItem
                    key={day}
                    value={day}
                    primaryText={day}
                    innerDivStyle={this.props.selectedMenuItemStyle}
                  />
                );
              })}
          </Select>

          {/*
          The value for this is sorta whacked because originally, era was
          a select list with two values: BCE and CE. BCE had a value of 0
          and CE had a value of 1. To avoid having to change all existing
          data, the checkbox is coded to be checked if era is 0 (essentially
          false) and not checked if era is 1 (essentially true). So when
          the user checks or unchecks the box, store the opposite of the
          checked value as the value for era. Must cast checked value to
          integer with value of 1 or 0 because the checked property is boolean.
          */}
          <Checkbox
            className="cms-checkbox"
            checked={this.state.value ? !this.state.value.era : false}
            labelPosition="right"
            label="BCE"
            onCheck={e => {
              const newValue = e.target.checked ? 0 : 1;
              this.handleChange("era", newValue);
            }}
          />
        </div>
        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

PartialDate.defaultProps = {
  inputStyle: {},
  labelStyle: { fontSize: 14 },
  required: false,
  styleName: "material",
  disabled: false
};

PartialDate.propTypes = {
  hasLabel: PropTypes.bool,
  id: PropTypes.string,

  disabled: PropTypes.bool,
  inputStyle: PropTypes.object,

  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node
  ]),
  labelStyle: PropTypes.object,

  name: PropTypes.string,
  handleInputChange: PropTypes.func,
  required: PropTypes.bool,
  styleName: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any
};
