import React, { Component } from "react";
import { Video } from "cloudinary-react";
import _isUndefined from "lodash/isUndefined";

import { Dialog } from "@material-ui/core";

import { Icon } from "../Icon";
import { ImageTag } from "../ImageTag";
//import PDFViewer from "../PDFViewer"; // temporarily removing to get build working
import "./AssetContainer.scss";

const AssetText = props => {
  if (props.show && props.metaConfig) {
    const { storyLayoutConfig } = props;
    const { title, caption, secondaryCaption, credit } = props.metaConfig;

    const showAssetTitle = storyLayoutConfig
      ? storyLayoutConfig.hasOwnProperty("showAssetTitle")
        ? storyLayoutConfig.showAssetTitle
        : true
      : true;

    const showAssetCaption = storyLayoutConfig
      ? storyLayoutConfig.hasOwnProperty("showAssetCaption")
        ? storyLayoutConfig.showAssetCaption
        : true
      : true;

    const showAssetCredit = storyLayoutConfig
      ? storyLayoutConfig.hasOwnProperty("showAssetCredit")
        ? storyLayoutConfig.showAssetCredit
        : true
      : true;

    return (
      <figcaption>
        <div className="caption-container">
          {showAssetTitle && title ? (
            <div
              className="caption-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : null}
          {showAssetCaption && caption ? (
            <div
              className="caption primary"
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          ) : null}
          {secondaryCaption ? (
            <div
              className="caption secondary"
              dangerouslySetInnerHTML={{ __html: secondaryCaption }}
            />
          ) : null}
          {showAssetCredit && credit ? (
            <div
              className="credit"
              dangerouslySetInnerHTML={{ __html: credit }}
            />
          ) : null}
        </div>
      </figcaption>
    );
  } else {
    return null;
  }
};

export class AssetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { openModal: false };
    this.processEditClick = this.processEditClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  // right now the modal is only used for assets that are videos....
  closeModal() {
    this.setState({ openModal: false });
  }

  openModal(elementID) {
    this.setState({ openModal: true });
  }

  // Functions used by Editing Mode
  processEditClick(value, event) {
    event.preventDefault();
    event.stopPropagation();
    if (
      typeof this.props.triggerEditFunc !== "undefined" &&
      this.props.isEditMode
    ) {
      this.props.triggerEditFunc(value);
    }
  }

  assetIsSet() {
    return (
      typeof this.props !== "undefined" &&
      (typeof this.props.cloudPublicId !== "undefined" ||
        typeof this.props.src !== "undefined")
    );
  }

  render() {
    const {
      layout, // slideshow or other scene layout name
      storyLayoutConfig,
      className,

      assetID,
      assetType, // image|audio|video
      use,

      // cloudinary
      cloudName,
      cloudPublicId,
      assetConfig, // object that should contain crop information
      location, // to determine protocol http or https

      metaConfig,
      styles,
      showAssetText,
      isLightboxIconVisible, // Used in destination app
      isDetailAvailable, // Used in CMS
      assetFit,
      focalPoint,
      margin,
      padding,
      background,
      frame,
      shadow,
      filter,
      buildInAnimation,
      buildInAnimationDelay,
      buildInAnimationSpeed,
      animatedEffect,
      animatedEffectSpeed,
      isLoopAnimatedEffect,
      isCaptionVisible,
      captionPosition,

      // multimedia
      controls,
      autoPlay,
      loop,
      playAsModal,
      poster,

      // cms related
      isEditMode,
      elementID,
      isActiveEditingElement,
      assetSelectorFunc,

      // sequencer
      assets,

      // optional for offline
      src,
      posterSrc
    } = this.props;
    const { assetW, asswtH } = this.state;

    // NOTE we need the abiilty if we are in edit mode to
    // 1. add a special onClick function
    // 2. allow the different types

    let content;
    // this value targets the assetConfig and pulls out any styles that shoudl over write for this use
    let assetStyleOverride = styles;

    // Custom classes that will be added to the Asset Container if they're defined
    let customClasses =
      metaConfig && metaConfig.classes ? metaConfig.classes : "";
    customClasses += assetFit ? ` ${assetFit}` : "";
    customClasses += focalPoint ? ` ${focalPoint}` : "";
    customClasses += margin ? ` ${margin}` : "";
    customClasses += padding ? ` ${padding}` : "";
    customClasses += background ? ` ${background}` : "";
    customClasses += frame ? ` ${frame}` : "";
    customClasses += shadow ? ` ${shadow}` : "";
    customClasses += filter ? ` ${filter}` : "";
    customClasses += buildInAnimation
      ? ` animate ${buildInAnimation} ${buildInAnimationSpeed} ${buildInAnimationDelay} ${animatedEffect} ${animatedEffectSpeed} ${
          isLoopAnimatedEffect ? "animate-effect-loop" : ""
        }`
      : "";
    customClasses += isCaptionVisible
      ? captionPosition
        ? ` ${captionPosition}`
        : ""
      : "";

    const showTextClass = showAssetText ? "with-text" : "";
    const lightboxIconClass = isLightboxIconVisible
      ? "lightbox-icon"
      : isDetailAvailable
      ? "lightbox-icon"
      : "";
    const extension = /(?:\.([^.]+))?$/;
    let useCloudinary = src ? false : true;
    const clickFunc = isEditMode
      ? event => {
          event.preventDefault();
          event.stopPropagation();
          this.assetIsSet()
            ? this.processEditClick(elementID, event)
            : assetSelectorFunc(elementID);
        }
      : null;
    const editClass = isEditMode
      ? `edit-mode ${layout} ${
          isActiveEditingElement ? "active-editing" : ""
        } ${this.assetIsSet() ? "" : "no-image"}`
      : "";

    // nothing to write out if the data isnt there
    // just as a catch if not editing AND no asset is present
    if (!cloudPublicId && !src && !isEditMode) {
      return null;
    }

    // if we are Editing and there is nothing add the placeholder
    if (!this.assetIsSet() && isEditMode) {
      return (
        <div
          className={`${
            use !== "background" ? "sc-module asset" : "asset"
          } ${customClasses}`}
        >
          <div
            className={`sc-edit sc-edit-module empty-selector ${editClass} ${className}`}
            onClick={clickFunc}
          >
            <div className="click-to-edit-container">
              <h3>Click to Edit</h3>
            </div>
          </div>
        </div>
      );
    }

    // destination/preview use
    switch (assetType) {
      case "video":
      case "cloudinaryVideo":
        // NOTE we dont' need the view modal IF EDITING
        // first pull from the props, however the modal is always autoplay
        let vidProps = { controls, autoPlay, loop, poster };

        let vidType = "mp4"; // this can later be read from project data and passed down
        // be sure backgronds ARE never played as modal
        if (
          (playAsModal || _isUndefined(playAsModal) || isEditMode) &&
          use !== "background"
        ) {
          // MODAL PLAYBACK VIDEO
          vidProps = { autoPlay, loop }; //don't have controlls on modal

          // the posterframe will be a special url
          // NOTE may want to use the cld core method to build url
          const imgSrc = useCloudinary
            ? `${
                location && location.protocol ? location.protocol : ""
              }//res.cloudinary.com/${cloudName}/video/upload${
                assetConfig && assetConfig.posterFrame
                  ? "/so_" + parseFloat(assetConfig.posterFrame, 2)
                  : ""
              }/${cloudPublicId}.jpg`
            : posterSrc;
          // NOTE could we leverage the ImageTag even with posters?
          // TODO: look into passing ref back up like i did in the other context
          // testing using the video tag  but we can't if its offline
          // NOTE: to try and avoid 0x0 pixel issue, we leverage the Cld Video component for the pdf poster, like a video, since the Cld Image component doesnt allow you to pass a url, which is needed here
          content = (
            <div
              className={`${className} video-poster-frame-container ${showTextClass}`}
              onClick={isEditMode ? clickFunc : this.openModal}
            >
              <ImageTag
                assetConfig={assetConfig}
                src={imgSrc}
                format={"jpg"}
                type={"video"}
                cloudName={cloudName}
                startOffset={
                  assetConfig && assetConfig.posterFrame
                    ? parseFloat(assetConfig.posterFrame, 2)
                    : ""
                }
                cloudPublicId={cloudPublicId}
                className={"img-wrapper"}
                layout={layout}
              >
                <Icon icon="play" className="play" />
                <AssetText
                  show={showAssetText}
                  metaConfig={metaConfig}
                  storyLayoutConfig={storyLayoutConfig}
                />
              </ImageTag>

              {/* --- VIDEO MODAL---  */}
              {isEditMode ? null : (
                <Dialog
                  className={`asset-modal ${layout}`}
                  modal={true}
                  open={this.state && this.state.openModal}
                  onRequestClose={this.closeModal}
                  autoScrollBodyContent={false}
                  paperClassName={"modal-paper"}
                  overlayClassName={`modal-overlay`}
                  bodyClassName={`modal-body`}
                  contentClassName={`modal-content`}
                  title={
                    <header>
                      <div className="modal-close" onClick={this.closeModal}>
                        <Icon icon="close" />
                      </div>
                    </header>
                  }
                >
                  <section className="video-content">
                    <div className={`${className} video-container col`}>
                      {useCloudinary ? (
                        <Video
                          style={assetStyleOverride}
                          cloudName={cloudName}
                          publicId={cloudPublicId}
                          {...vidProps}
                          autoPlay
                        />
                      ) : (
                        <video
                          {...vidProps}
                          autoPlay
                          style={assetStyleOverride}
                        >
                          <source src={src} type={`video/${vidType}`} />
                        </video>
                      )}
                    </div>
                  </section>
                </Dialog>
              )}
            </div>
          );
        } else {
          // INLINE PLAYBACK VIDEO
          content = (
            <div
              className={`${editClass} ${className} video-container ${showTextClass}`}
              onClick={clickFunc}
            >
              {useCloudinary ? (
                <Video
                  cloudName={cloudName}
                  publicId={cloudPublicId}
                  {...vidProps}
                  style={assetStyleOverride}
                />
              ) : (
                <video {...vidProps} style={assetStyleOverride}>
                  <source src={src} type={`video/${vidType}`} />
                </video>
              )}
              <AssetText
                show={showAssetText}
                metaConfig={metaConfig}
                storyLayoutConfig={storyLayoutConfig}
              />
            </div>
          );
        }

        break;

      case "pdf":
        const iframeSrc = useCloudinary
          ? `${
              location && location.protocol ? location.protocol : ""
            }//res.cloudinary.com/${cloudName}/image/upload/${cloudPublicId}`
          : src;
        const imgSrc = useCloudinary
          ? `${
              location && location.protocol ? location.protocol : ""
            }//res.cloudinary.com/${cloudName}/image/upload/f_auto,q_auto,c_fill,pg_1/v1/${cloudPublicId}.jpg`
          : posterSrc; //NOTE: need to be sure cloudPublicId has no extension
        // NOTE: to try and avoid 0x0 pixel issue, we leverage the Cld Video component for the pdf poster, like a video, since the Cld Image component doesnt allow you to pass a url, which is needed here
        content = (
          <div
            className={`${className} pdf-poster-frame-container ${showTextClass} ${editClass}`}
            onClick={isEditMode ? clickFunc : this.openModal}
          >
            <ImageTag
              src={imgSrc}
              className={"img-wrapper"}
              isPoster={true}
              cloudPublicId={cloudPublicId}
            >
              <Icon icon="touch" className="play play-touch" />
              <AssetText
                show={showAssetText}
                metaConfig={metaConfig}
                storyLayoutConfig={storyLayoutConfig}
              />
            </ImageTag>

            {/* --- VIDEO MODAL---  */}
            {isEditMode ? null : (
              <Dialog
                className={`asset-modal ${layout}`}
                modal={true}
                open={this.state && this.state.openModal}
                onRequestClose={this.closeModal}
                autoScrollBodyContent={false}
                paperClassName={"modal-paper"}
                overlayClassName={`modal-overlay`}
                bodyClassName={`modal-body`}
                contentClassName={`modal-content`}
                title={
                  <header>
                    <div className="modal-close" onClick={this.closeModal}>
                      <Icon icon="close" />
                    </div>
                  </header>
                }
              >
                <section className="pdf-content">
                  {/* <PDFViewer src={iframeSrc} /> */}
                </section>
              </Dialog>
            )}
          </div>
        );

        break;
      case "audio":
        // TODO:
        content = (
          <div
            className={`${editClass} ${className} audio-container ${showTextClass}`}
            onClick={clickFunc}
          >
            <audio />
            <AssetText
              show={showAssetText}
              metaConfig={metaConfig}
              storyLayoutConfig={storyLayoutConfig}
            />
          </div>
        );
        break;
      default:
        // NOTE: this compoenent accounts for non cloudinary images...
        // and tranfrmations creation
        return (
          <div
            className={`${
              use !== "background" ? "sc-module asset" : "asset"
            } ${customClasses}`}
          >
            <ImageTag
              {...this.props}
              className={`${editClass} ${className} image-container ${showTextClass} ${lightboxIconClass}`}
              imageStyle={assetStyleOverride}
              onClick={clickFunc}
            >
              <AssetText
                show={showAssetText}
                metaConfig={metaConfig}
                storyLayoutConfig={storyLayoutConfig}
              />
            </ImageTag>
            {/* Shadow div to create layer over module/element 
            to enable user to click to add/edit asset. */}
            {use !== "background" && (
              <div className="sc-edit sc-edit-module" onClick={clickFunc} />
            )}
          </div>
        );
    }

    return (
      <div
        className={`${
          use !== "background" ? "sc-module asset" : "asset"
        } ${customClasses}`}
      >
        {content}
      </div>
    );
  }
}
