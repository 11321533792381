import React from "react";
import PropTypes from "prop-types";

import { TextField } from "@material-ui/core";

import "./InputText.scss";

export class InputText extends React.Component {
  constructor(props) {
    super(props);
    // console.log('Setting def: ', props.valueFromInput || props.defaultValue || '');
    this.state = {
      value: props.valueFromInput || props.defaultValue || ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    if (this.state.value !== value) {
      this.setState({ value });
      if (typeof this.props.onChange !== "undefined") {
        this.props.onChange(this.props.name, value);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps - state',this.props.name, this.props.defaultValue, ' - nextProps', nextProps.defaultValue);
    if (this.props.defaultValue !== nextProps.defaultValue) {
      // console.log('change happening');
      this.setState({
        value: nextProps.defaultValue
      });
    }
  }

  render() {
    if (
      this.props.styleName === "material" ||
      this.props.styleName === "plain"
    ) {
      const hasLabel = this.props.hasLabel;
      const isTextarea = !!this.props.multiLine;

      const floatingLabelStyle =
        this.props.styleName === "plain"
          ? {
              padding: "4px 9px",
              color: "rgba(0, 0, 0, 0.4)",
              top: 10,
              ...this.props.labelStyle
            }
          : this.props.labelStyle;

      const floatingLabelFocusStyle =
        this.props.styleName === "plain"
          ? {
              top: 21
            }
          : undefined;

      const style =
        this.props.styleName === "plain"
          ? {
              marginTop: 14,
              height: 50,
              ...this.props.inputStyle
            }
          : this.props.inputStyle;

      const textareaStyle =
        this.props.styleName === "plain"
          ? {
              height: 50,
              marginTop: 14,
              marginBottom: -50,
              ...this.props.inputStyle
            }
          : this.props.inputStyle;

      // console.log('textareaStyle', this.props.name, hasLabel, this.props.label);

      const inputStyle =
        this.props.styleName === "plain"
          ? isTextarea
            ? {
                height: 96
              }
            : {
                backgroundColor: "#f6f6f6",
                fontSize: 13,
                marginTop: 0,
                borderRadius: 4,
                padding: hasLabel ? "16px 8px 0px 8px" : "0 9px"
              }
          : null;

      const textareaInputStyle =
        this.props.styleName === "plain"
          ? {
              backgroundColor: "#f6f6f6",
              borderRadius: 4,
              // height: 50,
              fontSize: 13,
              padding: hasLabel ? "20px 7px 6px 7px" : "13px 9px",
              marginTop: 0,
              marginBottom: 0
            }
          : null;

      return (
        <TextField
          type={this.props.type}
          className={`cms-input-text ${this.props.name} ${
            this.props.required &&
            (this.state.value === null ||
              this.state.value === undefined ||
              this.state.value === "")
              ? "invalid-input"
              : ""
          }`}
          id={this.props.id}
          underlineShow={this.props.styleName !== "plain"}
          disabled={this.props.disabled}
          name={this.props.name}
          value={this.state.value !== null ? this.state.value : ""}
          floatingLabelText={this.props.label}
          floatingLabelStyle={floatingLabelStyle}
          floatingLabelFocusStyle={floatingLabelFocusStyle}
          floatingLabelShrinkStyle={floatingLabelFocusStyle}
          multiLine={isTextarea}
          fullWidth={true}
          onChange={event => {
            const { value } = event.target;
            this.handleChange(value);
          }}
          style={isTextarea ? textareaStyle : style}
          textareaStyle={isTextarea ? textareaInputStyle : null}
          inputStyle={inputStyle}
          hintText={this.props.placeholder}
          autoComplete={this.props.autoComplete}
        />
      );
    } else {
      return (
        <div
          className={`boxed-input-text ${
            this.props.required &&
            (this.state.value === null ||
              this.state.value === undefined ||
              this.state.value === "")
              ? "invalid-input"
              : ""
          }`}
          id={this.props.id}
        >
          {this.props.label ? (
            <label
              className="input-label"
              htmlFor={this.props.name}
              style={this.props.labelStyle}
            >
              {this.props.label}
            </label>
          ) : null}
          {this.props.instructions ? (
            <div
              className="input-instructions"
              style={this.props.instructionsStyle}
            >
              {this.props.instructions}
            </div>
          ) : null}
          <div
            className="input-text-container"
            style={this.props.inputContainerStyle}
          >
            {this.props.multiLine ? (
              <textarea
                className="input-text"
                type={this.props.type}
                name={this.props.name}
                rows={this.props.rows ? this.props.rows : 2}
                required={this.props.required}
                onChange={this.handleChange}
                style={this.props.inputStyle}
                placeholder={this.props.placeholder}
                value={this.state.value}
                readOnly={this.props.disabled}
              />
            ) : (
              <input
                className="input-text"
                type={this.props.type}
                required={this.props.required}
                name={this.props.name}
                onChange={this.handleChange}
                style={this.props.inputStyle}
                placeholder={this.props.placeholder}
                value={this.state.value}
                readOnly={this.props.disabled}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

InputText.defaultProps = {
  defaultValue: "",
  inputStyle: {},
  labelStyle: { fontSize: 14 },
  multiLine: false,
  required: false,
  styleName: "material",
  disabled: false
};

InputText.propTypes = {
  defaultValue: PropTypes.any,
  hasLabel: PropTypes.bool,
  id: PropTypes.string,
  inputContainerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  inputStyle: PropTypes.object,
  instructions: PropTypes.string, //Additional information to display under label
  instructionsStyle: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node
  ]),
  labelStyle: PropTypes.object,
  multiLine: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  rows: PropTypes.number, // If it's a multiline textarea we can indicate the number of rows
  styleName: PropTypes.string,
  value: PropTypes.string
};
