import React, { Component } from "react";
import PropTypes from "prop-types";

import { MenuItem, Select as MUISelect } from "@material-ui/core";

import "./Select.scss";

export class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected || 0,
      value: this.props.value
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, index, value) {
    // we store the index but pass the value
    this.setState({ selected: index, value });
    if (typeof this.props.onChange !== "undefined") {
      // @TODO Remove dependency with dataProperty, 'name' is more appropriate.
      const name = this.props.dataProperty || this.props.name;
      this.props.onChange(name, value);
    }
  }

  componentWillUpdate() {
    if (this.state.selected !== this.props.selected && this.props.needRefresh) {
      this.setState({ selected: this.props.selected, value: this.props.value });
    }
  }

  componentDidUpdate(prevProp) {
    if (prevProp.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const inputStyle =
      this.props.styleName === "plain"
        ? {
            backgroundColor: "#f6f6f6",
            fontSize: 13,
            marginTop: 0,
            borderRadius: 4,
            width: "100%",
            height: 65,
            boxSizing: "border-box",
            padding: "0 0 0 11px"
          }
        : this.props.style;

    const underlineStyle =
      this.props.styleName === "plain"
        ? {
            display: "none"
          }
        : this.props.underlineStyle;

    const floatingLabelStyle =
      this.props.styleName === "plain"
        ? {
            top: 27,
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.4)"
          }
        : undefined;

    const menuStyle =
      this.props.styleName === "plain"
        ? {
            marginTop: 5
          }
        : this.props.menuStyle;

    return (
      <div className={`cms-select cms-input-text ${this.props.dataProperty}`}>
        {this.props.label ? (
          <label
            className="input-label"
            htmlFor={this.props.name}
            style={this.props.labelStyle}
          >
            {this.props.label}
          </label>
        ) : null}
        {this.props.instructions ? (
          <div
            className="input-instructions"
            style={this.props.instructionsStyle}
          >
            {this.props.instructions}
          </div>
        ) : null}
        <MUISelect
          value={this.state.value}
          onChange={this.handleChange}
          hintText={this.props.hintText}
          hintStyle={this.props.hintStyle}
          key={this.props.id}
          style={inputStyle}
          underlineStyle={underlineStyle}
          floatingLabelText={this.props.floatingLabel}
          floatingLabelStyle={floatingLabelStyle}
          menuStyle={menuStyle}
          listStyle={this.props.listStyle}
          selectedMenuItemStyle={this.props.selectedMenuItemStyle}
          menuItemStyle={this.props.menuItemStyle}
          floatingLabelFixed={
            this.props.floatingLabel || this.props.label ? true : false
          }
          multiple={this.props.multiple}
        >
          {this.props.options &&
            this.props.options.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  value={option.value}
                  primaryText={option.label.en || option.label}
                  innerDivStyle={this.props.selectedMenuItemStyle}
                />
              );
            })}
        </MUISelect>
      </div>
    );
  }
}

Select.defaultProps = {
  selected: 0,
  needRefresh: false,
  hintStyle: {
    bottom: 3,
    left: 20
  },
  style: { width: 150 },
  underlineStyle: { color: "#abcdeff" }
};

Select.propTypes = {
  selected: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number
  ]),
  dataProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  needRefresh: PropTypes.bool,
  style: PropTypes.object,
  underlineStyle: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelStyle: PropTypes.object,
  floatingLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  menuStyle: PropTypes.object,
  listStyle: PropTypes.object,
  selectedMenuItemStyle: PropTypes.object,
  menuItemStyle: PropTypes.object,
  instructions: PropTypes.string,
  instructionsStyle: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string
};
