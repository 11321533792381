import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import _debounce from "lodash/debounce";
import _map from "lodash/map";

import { IconButton, Button } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import Logger from "utils/logger";
import { PreviewSelector, SlideshowScene } from "components";
import ContentContainer from "modules/ContentContainer";
import ThemeSelector from "modules/ThemeSelector";
import { slideshowEditorMapStateToProps } from "mapToProps/sceneEditor";
import BackgroundEditModal from "./BackgroundEditModal";
import StyleEditModal2 from "./StyleEditModal2";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";
import styles from "./styles.scss";

export class SlideshowEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetSelectorOpen: false,
      isBackgroundModalOpen: false,
      isSceneStyleModalOpen: false,
      isContentAreaStyleModalOpen: false,
      focusedContentAreaID: null,
      activeElement: null,
      displayModalChanges: false,
      layoutSelectorEditMode: false
    };

    this.handleThemeOptionChange = this.handleThemeOptionChange.bind(this);
    this.openThemeSelector = this.openThemeSelector.bind(this);
    this.closeThemeSelector = this.closeThemeSelector.bind(this);
    this.handleThemeSelect = this.handleThemeSelect.bind(this);
    this.openAssetSelector = this.openAssetSelector.bind(this);
    this.handleAssetEditorEditClick =
      this.handleAssetEditorEditClick.bind(this);
    this.openLayoutSelector = this.openLayoutSelector.bind(this);
    this.closeBackgroundModal = this.closeBackgroundModal.bind(this);
    this.openBackgroundModal = this.openBackgroundModal.bind(this);
    this.openSceneStyleModal = this.openSceneStyleModal.bind(this);
    this.closeSceneStyleModal = this.closeSceneStyleModal.bind(this);
    this.handleSceneStyleModalSaveClick =
      this.handleSceneStyleModalSaveClick.bind(this);
    this.openContentAreaStyleModal = this.openContentAreaStyleModal.bind(this);
    this.closeContentAreaStyleModal =
      this.closeContentAreaStyleModal.bind(this);
    this.handleContentAreaStyleModalSaveClick =
      this.handleContentAreaStyleModalSaveClick.bind(this);
    this.processBackgroundSave = this.processBackgroundSave.bind(this);
    this.processStyleSave = this.processStyleSave.bind(this);
  }

  componentDidMount() {
    this.props.dispatchMount();
  }

  render() {
    const {
      storyID,
      storyData,
      styleForms,
      userPreferences,
      accountID,
      accountProjectDefaults,
      userID,
      storyClasses,
      activeEditingScene,
      handleSceneStyleChange,
      handleContentAreaStyleChange,
      userPreferencesSelector
    } = this.props;

    const topBar = (
      <div className="slideshow-editor-topbar">
        <Button
          className="header-select"
          style={{ textTransform: "none" }}
          onClick={this.openThemeSelector}
        >
          Change Preview Theme
        </Button>

        <PreviewSelector
          value={userPreferences.preview}
          userPreferencesSelector={userPreferencesSelector}
        />

        {this.props.sceneType !== "cover" ? (
          <div className="scene-navigation-component">
            {this.props.goPrevURL ? (
              <IconButton
                style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
                disableTouchRipple
                className="back-arrow"
                containerElement={<Link to={this.props.goPrevURL} />}
              >
                <ChevronLeft viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
              </IconButton>
            ) : null}
            <div className="slide-numbers-container">
              {this.props.curSlideNo ? this.props.curSlideNo : null}
              &nbsp; of &nbsp;
              {this.props.totalSlideNo ? this.props.totalSlideNo : null}
            </div>
            {this.props.goNextURL ? (
              <IconButton
                style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
                disableTouchRipple
                className="back-arrow"
                containerElement={<Link to={this.props.goNextURL} />}
              >
                <ChevronRight viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
              </IconButton>
            ) : (
              <div className="empty-arrow" />
            )}
          </div>
        ) : null}
      </div>
    );

    const headerActions = (
      <div className="buttons-container">
        <Button
          style={{ textTransform: "none", textAlign: "left" }}
          onClick={this.openLayoutSelector}
        >
          Layout
        </Button>
        <Button
          style={{ textTransform: "none", textAlign: "left" }}
          onClick={this.openBackgroundModal}
        >
          Background
        </Button>
        <Button
          style={{ textTransform: "none", textAlign: "left" }}
          onClick={this.openSceneStyleModal}
        >
          Style
        </Button>
      </div>
    );

    // Injecting asset editor handlers
    if (this.props.editingForm) {
      Object.keys(this.props.editingForm).forEach(key => {
        if (this.props.editingForm[key].type === "assetEditor") {
          this.props.editingForm[key].handleEditClick =
            this.handleAssetEditorEditClick;
          this.props.editingForm[key].handleDeleteClick =
            this.handleAssetEditorDeleteClick;
        }
      });
    }

    const themeStyleSheet = userPreferences.theme
      ? `dest-theme-${userPreferences.theme}.css`
      : `dest-theme-${accountProjectDefaults.theme.themeId}.css`;

    return (
      <div className="slideshow-editor">
        <Helmet>
          <title>
            {this.props.sceneType === "cover"
              ? "Cover Editor"
              : "Slideshow Editor"}
          </title>

          <link
            rel="stylesheet"
            href={"https://storycrafter.co/sc-structure-sc4.css"}
          />

          <link
            rel="stylesheet"
            href={`https://storycrafter.co/${themeStyleSheet}`}
          />
        </Helmet>

        <ContentContainer
          backgroundColor={styles.contentContainerBack}
          isLoading={this.props.isLoading}
          sidebarMode={this.props.sidebarMode}
          closeAction={this.props.goBackURL}
          headerTheme="light"
          title={
            this.props.sceneType === "cover" ? "Cover Editor" : "Slide Editor"
          }
          headerMode="extended"
          headerActions={headerActions}
          topBar={topBar}
        >
          <div
            className={`slideshow-editor-container scene-container content preview-${
              userPreferences.preview || "dm"
            } ${storyClasses ? storyClasses : ""}`}
          >
            <SlideshowScene
              elements={activeEditingScene ? activeEditingScene.elements : {}}
              backgroundColor={
                activeEditingScene &&
                activeEditingScene.elements &&
                activeEditingScene.elements.background
                  ? activeEditingScene.elements.background.color
                  : undefined
              }
              classNames={activeEditingScene.classNames}
              styles={activeEditingScene.styles}
              layout={
                activeEditingScene ? activeEditingScene.layout : undefined
              }
              layoutElements={
                activeEditingScene ? activeEditingScene.layoutElements : {}
              }
              template={activeEditingScene ? activeEditingScene.template : []}
              isEditMode
              playAsModal={false}
              onContentAreaClick={this.openContentAreaStyleModal}
              triggerEditFunc={this.props.setActiveEditingElement}
              activeEditingElement={this.props.elementID}
              backClickHandler={() => {
                this.props.setActiveEditingElement("");
              }}
              assetSelectorFunc={value => this.openAssetSelector(value, false)}
              storyProperties={storyData}
            />
          </div>
        </ContentContainer>

        {this.state.isBackgroundModalOpen && (
          <BackgroundEditModal
            open
            backgroundData={
              activeEditingScene &&
              activeEditingScene.elements &&
              activeEditingScene.elements.background &&
              Object.keys(activeEditingScene.elements.background).length > 0
                ? {
                    ...activeEditingScene.elements.background,
                    ...activeEditingScene.elements.background.styles,
                    ...activeEditingScene.elements.background.classes
                  }
                : undefined
            }
            initialColor={
              activeEditingScene &&
              activeEditingScene.elements &&
              activeEditingScene.elements.background &&
              Object.keys(activeEditingScene.elements.background).length > 0
                ? activeEditingScene.elements.background.color
                  ? activeEditingScene.elements.background.color
                  : undefined
                : undefined
            }
            handleSave={this.processBackgroundSave}
            closeAction={this.closeBackgroundModal}
            openAssetSelectorFunc={() =>
              this.openAssetSelector("background", false)
            }
            handleImageEditClick={props =>
              this.props.handleBackgroundImageEditClick(props)
            }
          />
        )}

        {this.state.isSceneStyleModalOpen && (
          <StyleEditModal2
            title="Slide Styles"
            tabs={styleForms.scene.tabs}
            forms={styleForms.scene.forms}
            formData={
              activeEditingScene.styles && activeEditingScene.styles.scene
                ? activeEditingScene.styles.scene
                : null
            }
            onInputChange={(name, value) => handleSceneStyleChange(name, value)}
            onCancel={this.closeSceneStyleModal}
            onSave={this.handleSceneStyleModalSaveClick}
          />
        )}

        {/* handleSave={this.processStyleSave}
        closeAction={this.closeStyleModal} */}
        {this.state.isContentAreaStyleModalOpen && (
          <StyleEditModal2
            title="Content Area Styles"
            tabs={styleForms.contentArea.tabs}
            forms={styleForms.contentArea.forms}
            formData={
              activeEditingScene.styles &&
              activeEditingScene.styles.contentAreas &&
              activeEditingScene.styles[
                `area${this.state.focusedContentAreaID}`
              ]
                ? activeEditingScene.styles[
                    `area${this.state.focusedContentAreaID}`
                  ]
                : null
            }
            onInputChange={(name, value) =>
              handleContentAreaStyleChange(
                this.state.focusedContentAreaID,
                name,
                value
              )
            }
            onCancel={this.closeContentAreaStyleModal}
            onSave={this.handleContentAreaStyleModalSaveClick}
          />
        )}

        {this.state.isThemeSelectorOpen && (
          <ThemeSelector
            isOpen
            handleThemeSelect={this.handleThemeSelect}
            handleCloseClick={this.closeThemeSelector}
            selectedThemeId={userPreferences.theme || 0}
            isModal
          />
        )}
      </div>
    );
  }

  handleThemeOptionChange(event, index, value) {
    this.props.handleThemeOptionChange(value);
  }

  /* THEME MANAGEMENT  */
  openThemeSelector(e) {
    Logger.debug("[SlideshowEditor] - openThemeSelector");
    e.preventDefault();
    this.setState({ isThemeSelectorOpen: true });
  }

  closeThemeSelector() {
    this.setState({ isThemeSelectorOpen: false });
  }

  handleThemeSelect(themeId) {
    this.closeThemeSelector();
    this.props.handleThemeOptionChange(themeId);
  }

  handleAssetEditorEditClick(value) {
    this.setState({ imageCropperOpen: true });
  }

  openAssetSelector(value, multiple) {
    this.closeBackgroundModal();
    this.props.openAssetSelectorFunc(value, multiple);
  }

  openLayoutSelector() {
    this.props.openLayoutSelectorFunc();
  }

  openBackgroundModal() {
    this.props.setActiveElementFunc("background");
    this.setState({ isBackgroundModalOpen: true });
  }

  closeBackgroundModal() {
    this.setState({ isBackgroundModalOpen: false });
  }

  openSceneStyleModal() {
    this.props.setActiveElementFunc("style");
    this.setState({ isSceneStyleModalOpen: true });
  }

  closeSceneStyleModal() {
    this.setState({ isSceneStyleModalOpen: false });
    this.props.handleStyleSettingsCancelClick();
  }

  handleSceneStyleModalSaveClick() {
    this.setState({
      isSceneStyleModalOpen: false
    });
    this.props.handleSceneStyleSettingsSaveClick();
  }

  openContentAreaStyleModal(areaID) {
    this.setState({
      isContentAreaStyleModalOpen: true,
      focusedContentAreaID: areaID
    });
  }

  closeContentAreaStyleModal() {
    this.setState({
      isContentAreaStyleModalOpen: false,
      focusedContentAreaID: null
    });
    this.props.handleStyleSettingsCancelClick();
  }

  handleContentAreaStyleModalSaveClick() {
    this.setState({
      isContentAreaStyleModalOpen: false,
      focusedContentAreaID: null
    });
    this.props.handleSceneStyleSettingsSaveClick();
  }

  processBackgroundSave(data) {
    Logger.debug({ data }, "[SLIDESHOW EDITOR] - processBackgroundSave");

    const buildData = { ...data.background };

    // Define color property on the background object
    // using the backgroundColor property.
    buildData.color =
      data.backgroundColor && data.backgroundColor.hex
        ? data.backgroundColor.hex
        : data.backgroundColor;

    /*
     Some properties that are injected into the background
     component in the destination need to be segregated out
     into the element styles object when passed to the API.
     Other props are class props that will be manually 
     written out to different contexts, and they need to be
     moved to the classes object.
     */
    if (typeof data.background.styles === "undefined") {
      buildData.styles = {};
    }
    if (typeof data.background.classes === "undefined") {
      buildData.classes = {};
    }

    const stylePropsToRemap = ["objectFit", "objectPosition", "repeat"];
    const classPropsToRemap = [
      "themeBackground",
      "backgroundFilter",
      "backgroundEffect",
      "backgroundAnimation",
      "backgroundAnimationSpeed"
    ];

    stylePropsToRemap.forEach(prop => {
      if (data.background && data.background[prop] !== undefined) {
        buildData.styles[prop] = data.background[prop];
      }
      delete buildData[prop];
    });

    classPropsToRemap.forEach(prop => {
      if (data.background && data.background[prop] !== undefined) {
        buildData.classes[prop] = data.background[prop];
      }
      delete buildData[prop];
    });

    /*
    Not sure what purpose the following objects serve
    on the background object in the CMS, but they don't
    belong on the background element root in the DB.
    */
    delete buildData.formType;
    delete buildData.config;

    this.closeBackgroundModal();
    this.props.updateBackground(buildData);
  }

  processStyleSave(classNames) {
    // this.closeStyleModal();
    this.props.changeClassNames(classNames);
  }

  deleteBackgroundImage() {}
}

SlideshowEditor.contextTypes = {
  router: PropTypes.object
};

SlideshowEditor.defaultProps = {
  isLoading: false,
  goNextURL: "",
  goPrevURL: ""
};

// seems like all we nned to pass is the scene being edited...
SlideshowEditor.propTypes = {
  isLoading: PropTypes.bool,
  // sidebarMode: PropTypes.string,
  slugBase: PropTypes.string, // will be passed down from SceneEditor?
  storyData: PropTypes.object, // data for this story
  // scenes: PropTypes.array, // we filtered the main scenes store into an array that only has this stories scenes
  // scenesArray: PropTypes.array, // TODO: change to sceneIDs
  storyID: PropTypes.string,
  sceneID: PropTypes.string,
  elementID: PropTypes.string,
  needsSaving: PropTypes.bool, // the dirty flag if the scene needs to be saved...
  activeEditingScene: PropTypes.object, // Active Scene we are editing
  activeEditingElement: PropTypes.object, // Active Editing Element
  openAssetSelectorFunc: PropTypes.func, // opens asset selector
  openLayoutSelectorFunc: PropTypes.func, // opens layout selector
  setActiveElementFunc: PropTypes.func, // Function to set active element
  curSlideNo: PropTypes.number, // Current number of slide
  totalSlideNo: PropTypes.number, // Total number of slides
  goPrevURL: PropTypes.string, // URL to navigate to Previous SLIDE
  goNextURL: PropTypes.string // URL to navigate to Next SLIDE
};

function mapStateToProps(state, ownProps) {
  return slideshowEditorMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { storyData, scenes, storyID, slugBase, sceneID, elementID } = ownProps;
  const { userPreferencesSelector, sceneType } = stateProps;

  const handleSearchDebounced = _debounce(
    (property, value) =>
      dispatchProps.setDataFromInput.apply(this, [
        property,
        value,
        storyID,
        sceneID,
        elementID
      ]),
    500
  );

  const debounceHandleSceneStyleChange = _debounce(
    (sceneID, name, value) =>
      dispatchProps.dispatchSceneStyleChange.apply(this, [
        sceneID,
        name,
        value
      ]),
    500
  );

  const debounceHandleContentAreaStyleChange = _debounce(
    (sceneID, areaID, name, value) =>
      dispatchProps.dispatchContentAreaStyleChange.apply(this, [
        sceneID,
        areaID,
        name,
        value
      ]),
    500
  );

  return Object.assign({}, stateProps, {
    dispatchMount: () => dispatchProps.slideshowSceneEditorMount(),

    handleInputChange: (property, value) =>
      handleSearchDebounced(property, value),

    updateBackground: data => {
      dispatchProps.updateScene(
        sceneID,
        data,
        null,
        "slideshow-slide",
        null,
        "background"
      );
    },

    handleBackgroundImageEditClick: props => {
      // I have no idea why this function can be accessed here, but
      // not in the render() function above.
      Logger.debug(
        { props },
        "SlideshowEditor: handleBackgroundImageEditClick"
      );
      ownProps.openImageCropperFunc("background");
    },

    setActiveEditingElement: elementID => {
      // NOTE: seems we are getting the use NOT the ID back from the slideshow viewer
      const url = elementID
        ? `${slugBase}/${sceneID}/elements/${elementID}`
        : `${slugBase}/${sceneID}`;
      ownProps.history.push(url);
    },

    openAssetSelectorFunc: (value, multiple) =>
      ownProps.openAssetSelectorFunc(value, multiple),

    openLayoutSelectorFunc: () => ownProps.openLayoutSelectorFunc(),

    setActiveElementFunc: element => ownProps.setActiveElementFunc(element),

    // changeClassNames: classNames =>
    //   dispatchProps.changeClassNames(storyID, sceneID, classNames),

    //
    // As of 11/28/2018, it doesn't appear that deleteElement()
    // is being used anywhere.
    //
    // deleteElement: (sceneID, elementID) => {
    //   if (elementID === 'background') {
    //     dispatchProps.updateScene(
    //       sceneID,
    //       { assetID: null, type: 'asset' },
    //       null,
    //       'slideshow-slide',
    //       null,
    //       'background',
    //     );
    //   } else {
    //     dispatchProps.setActiveEditingEålement(null);
    //     dispatchProps.deleteSceneAssetElement(sceneID, elementID);
    //   }
    // },

    handleThemeOptionChange: value =>
      dispatchProps.dispatchUserPreferenceChange(
        userPreferencesSelector,
        "theme",
        value
      ),

    handleSceneStyleChange: (name, value) =>
      debounceHandleSceneStyleChange(sceneID, name, value),

    handleContentAreaStyleChange: (areaID, name, value) =>
      debounceHandleContentAreaStyleChange(sceneID, areaID, name, value),

    handleSceneStyleSettingsSaveClick: () =>
      dispatchProps.dispatchSceneStyleSettingsSaveClick(sceneID),

    handleStyleSettingsCancelClick: () => dispatchProps.revertScene()
  });
}

export default withRouter(
  connect(mapStateToProps, sceneActions, mergeProps)(SlideshowEditor)
);
