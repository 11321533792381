import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import _map from "lodash/map";
import _startCase from "lodash/startCase";
import _cloneDeep from "lodash/cloneDeep";

import { Button } from "@material-ui/core";

import { CardBrowser, Dialog, TableGrid } from "components";
import Logger from "utils/logger";
import { collectionItemsBrowserMapStateToProps } from "mapToProps/sceneEditor";
import ContentContainer from "modules/ContentContainer";
import * as storyActions from "actions/storyEditor";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";

class CollectionItemsBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true,
      deleteModalOpen: false,
      selectedSceneIds: [],
      selectMode: false // For card browser
    };

    // NOTE: the bindings allow for use of this reference
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
    this.handleCardCheck = this.handleCardCheck.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this);
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false });
    // const selected = [];
    // _map(this.state.selected, item => {
    //   selected.push(this.props.tableGridItems[item + 1].id);
    // });
    // this.props.deleteScene(selected);
    this.props.deleteScenes(this.state.selectedSceneIds);
  }

  // For table view
  handleRowSelection(rowIndexes) {
    const selectedSceneIds = [];
    rowIndexes.forEach(rowIndex => {
      selectedSceneIds.push(this.props.tableGridItems[rowIndex + 1].id);
    });
    this.setState({ selectedSceneIds });
  }

  // For card browser
  handleCardCheck(sceneId) {
    Logger.debug({ sceneId }, "[COLLECTIONS ITEMS BROWSER] - handleCardCheck");
    const selectedSceneIds = [...this.state.selectedSceneIds];
    const index = selectedSceneIds.indexOf(sceneId);

    if (index === -1) {
      // sceneId not in list - add it.
      selectedSceneIds.push(sceneId);
    } else {
      // sceneId in list - remove it.
      selectedSceneIds.splice(index, 1);
    }
    const selectMode = selectedSceneIds.length > 0;

    this.setState({ selectedSceneIds, selectMode });
  }

  // For card browser
  handleCardClick(id) {
    Logger.debug({ id }, "[COLLECTION ITEMS BROWSER] - handleCardClick");
    /*
    For some reason, this method is called when the card is 
    clicked: Once with the correct ID and a second time with
    null.

    This method is also called when an item is deleted.
    No idea why that happens.
    */
    if (id) {
      this.props.handleEditClick(id);
    }
  }

  // For card browser: User clicked outside cards
  handleBackgroundClick() {
    this.setState({ selectedSceneIds: [], selectMode: false });
  }

  componentDidUpdate() {
    if (this.props.navigateToNew) {
      this.props.goToScene(this.props.navigateToNew);
    }
  }

  render() {
    const {
      pageTitle,
      isLoading,
      sidebarMode,
      sceneType,
      header,
      tableGridItems,
      minCardSize,
      // cardBrowserConfig,
      cardBrowserItems
    } = this.props;

    // Dispatch Methods
    const { handleNewClick, handleEditClick, setSceneOrder } = this.props;

    if (isLoading) {
      return <div>{`Loading ${sceneType}s to browse.`}</div>;
    }

    const topBar = (
      <div className="filters-bar-container">
        {this.state.selectedSceneIds.length > 0 ? (
          <Button
            id="delete-button"
            variant="contained"
            color="primary"
            className="new-asset-button-v2"
            style={{ textTransform: "none" }}
            onTouchTap={e => {
              e.stopPropagation();
              e.preventDefault();
              this.openDeleteModal();
            }}
          >
            DELETE
          </Button>
        ) : (
          <Button
            id="new-button"
            variant="contained"
            color="primary"
            className="new-asset-button-v2"
            style={{ textTransform: "none" }}
            onTouchTap={e => {
              e.stopPropagation();
              e.preventDefault();
              handleNewClick();
            }}
          >
            NEW
          </Button>
        )}
      </div>
    );

    const headerStyle = {
      backgroundColor: "#f2f2f2"
    };

    return (
      <div className="scene-list-browser">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <ContentContainer
          backgroundColor="white"
          isLoading={isLoading}
          className="content-container"
          sidebarMode={sidebarMode}
          topBar={topBar}
        >
          {cardBrowserItems && cardBrowserItems.length > 0 ? (
            <CardBrowser
              className="collection-items-browser"
              thumbHelperClassName="collection-item"
              minCardSize={minCardSize}
              items={cardBrowserItems}
              itemName={sceneType}
              setCardMargin={20}
              enableKeys={false}
              showButtons // What does this do?
              isSequentiable
              executeOnReSort={setSceneOrder}
              selectHandler={this.handleCardCheck}
              selectMode={this.state.selectMode}
              selected={this.state.selectedSceneIds}
              exclusiveSelectHandler={this.handleCardClick}
              backClickHandler={this.handleBackgroundClick}
            />
          ) : (
            <TableGrid
              items={tableGridItems}
              header={header}
              handleRowSelectionFunc={this.handleRowSelection}
              idNode="id"
              headerStyle={headerStyle}
              multiSelectable
              sortable
              editAction={handleEditClick}
            />
          )}
        </ContentContainer>
        <Dialog
          content={`Once deleted, these ${sceneType}s cannot be restored`}
          handleConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          handleCancel={this.closeDeleteModal}
          bodyStyle={{ paddingTop: 20 }}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.deleteModalOpen}
          title={`Delete ${_startCase(sceneType)}?`}
        />
      </div>
    );
  }
}

CollectionItemsBrowser.defaultProps = {
  isLoading: false,
  storyBrowserUIState: {}
};

CollectionItemsBrowser.propTypes = {
  isLoading: PropTypes.bool,
  tableGridItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

function mapStateToProps(state, ownProps) {
  return collectionItemsBrowserMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { collectionID, collectionSceneTypeID, slugBase, titleIsLocalized } =
    stateProps;
  return Object.assign({}, stateProps, {
    handleEditClick: sceneID => {
      const url = `${slugBase}/${sceneID}`;
      ownProps.history.push(url);
    },
    handleNewClick: () => {
      const sceneData = {
        type: collectionSceneTypeID,
        title: ""
      };
      dispatchProps.createScene(
        collectionID,
        sceneData,
        null,
        titleIsLocalized
      );
    },
    goToScene: sceneID => {
      ownProps.history.push(`${slugBase}/${sceneID}`);
    },
    deleteScenes: selectedSceneIds => {
      dispatchProps.deleteScene(collectionID, selectedSceneIds);
    },
    setSceneOrder: (oldIndex, newIndex) =>
      dispatchProps.setSceneOrder(collectionID, oldIndex, newIndex)
  });
}

// 12/29/22: I don't think we need storyActions.
export default connect(
  mapStateToProps,
  { ...storyActions, ...sceneActions },
  mergeProps
)(CollectionItemsBrowser);
