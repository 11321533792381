import React from "react";
import PropTypes from "prop-types";

import _map from "lodash/map";

import {
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@material-ui/core";
import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  ModeEdit
} from "@material-ui/icons";

export class TableGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.convertToArray(this.props.items),
      selected: [],
      sortByColumn: null,
      sortByTypeOptions: ["asc", "desc", "none"],
      sortByTypeKey: 2
    };
    this.handleRowSelection = this.handleRowSelection.bind(this);
    this.clickHeaderHandler = this.clickHeaderHandler.bind(this);
    this.convertToArray = this.convertToArray.bind(this);
  }

  convertToArray(object) {
    let array = [];
    _map(object, item => {
      array.push(item);
    });
    const state = this.state;
    const sortBy = state ? state.sortByTypeOptions[state.sortByTypeKey] : null;
    if (state && this.props.sortable && sortBy !== "none") {
      array.sort((a, b) => {
        let selectorKey;
        _map(this.props.header, (item, key) => {
          if (item.label === state.sortByColumn) {
            selectorKey = key;
          }
        });
        const aValue = a.columns[selectorKey].label;
        const bValue = b.columns[selectorKey].label;
        if (aValue > bValue) return sortBy === "asc" ? 1 : -1;
        else if (aValue < bValue) return sortBy === "asc" ? -1 : 1;
        else return 0;
      });
    }
    return array;
  }

  clickHeaderHandler(columnName) {
    const newTypeKey =
      this.state.sortByTypeKey === 2 ? 0 : this.state.sortByTypeKey + 1;
    this.setState({
      sortByTypeKey: newTypeKey,
      sortByColumn: columnName
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ items: this.convertToArray(nextProps.items) });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sortByColumn !== this.state.sortByColumn ||
      prevState.sortByTypeKey !== this.state.sortByTypeKey
    ) {
      this.setState({ items: this.convertToArray(this.props.items) });
    }
  }

  isSelected(index) {
    return this.state.selected.indexOf(index) !== -1;
  }

  handleRowSelection(selectedRows) {
    this.setState({
      selected: selectedRows
    });
    const selectedItems = this.state.items.reduce(
      (selectedItems, item, index) => {
        if (selectedRows.includes(index)) {
          selectedItems.push(item);
        }
        return selectedItems;
      },
      []
    );
    if (typeof this.props.handleRowSelectionFunc !== "undefined") {
      this.props.handleRowSelectionFunc(selectedRows, selectedItems);
    }
  }

  render() {
    const iconStyles = {
      marginLeft: 12,
      marginBottom: -4,
      height: 18,
      width: 18
    };

    return (
      <div className="tablegrid-container">
        <Table
          onRowSelection={this.handleRowSelection}
          multiSelectable={this.props.multiSelectable}
          // fixedHeader={false}
          // style={{
          //   "tableLayout": "auto"
          // }}
        >
          <TableHead
            style={this.props.headerStyle}
            enableSelectAll={this.props.multiSelectable}
            displaySelectAll={this.props.multiSelectable}
          >
            <TableRow>
              {_map(this.props.header, item => {
                return (
                  <TableCell
                    key={item[this.props.idNode]}
                    style={{ fontWeight: 600 }}
                  >
                    <a onClick={() => this.clickHeaderHandler(item.label)}>
                      {item.label}
                    </a>
                    {this.state.sortByColumn === item.label ? (
                      this.state.sortByTypeKey === 2 ? null : this.state
                          .sortByTypeKey === 0 ? (
                        <ArrowUpward style={iconStyles} color="#9e9e9e" />
                      ) : (
                        <ArrowDownward style={iconStyles} color="#9e9e9e" />
                      )
                    ) : null}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {_map(this.state.items, (item, key) => {
              return (
                <TableRow
                  key={key}
                  selected={this.isSelected(key)}
                  selectable={
                    item.selectable !== undefined ? item.selectable : true
                  }
                >
                  {_map(item.columns, column => {
                    return (
                      <TableCell key={column[this.props.idNode]}>
                        {column.label &&
                        column.label.substring(0, 4) === "rgba" ? (
                          <div
                            className="color-value"
                            style={{ display: "flex" }}
                          >
                            <div
                              className="color-chip"
                              style={{
                                marginRight: 6,
                                width: 15,
                                height: 15,
                                backgroundColor: column.label,
                                boxShadow: "1px 1px 5px grey"
                              }}
                            ></div>
                            <span
                              dangerouslySetInnerHTML={{ __html: column.label }}
                            />
                          </div>
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{ __html: column.label }}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                  {typeof this.props.viewAction !== "undefined" ? (
                    <TableCell style={{ textAlign: "right" }}>
                      <IconButton
                        onClick={() =>
                          this.props.viewAction(item[this.props.idNode])
                        }
                      >
                        <ArrowForward />
                      </IconButton>
                    </TableCell>
                  ) : null}
                  {typeof this.props.editAction !== "undefined" ? (
                    <TableCell style={{ textAlign: "right" }}>
                      <IconButton
                        onClick={() =>
                          this.props.editAction(item[this.props.idNode])
                        }
                      >
                        <ModeEdit />
                      </IconButton>
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

TableGrid.defaultProps = {
  multiSelectable: false
};

TableGrid.propTypes = {
  idNode: PropTypes.string,
  editAction: PropTypes.func,
  viewAction: PropTypes.func,
  handleRowSelectionFunc: PropTypes.func,
  multiSelectable: PropTypes.bool,
  sortable: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  //Styles
  headerStyle: PropTypes.object
};
